.board__acorn {
  @apply mb-[12px] h-[80px] w-[400px] pl-[16px] flex items-center;

  background-image: url('../../../../assets/images/button-menu-default.png');
  background-size: contain;
  background-repeat: no-repeat;

  .board__acorn__number {
    @apply flex flex-1 justify-center text-[#953D22] text-[32px] leading-[48px] font-paytone;
  }
}
.board__feature {
  @apply mt-[24px] mb-[12px] h-[6rem] w-[400px] pl-[16px] flex items-center;
  
  background-image: url('../../../../assets/images/button-menu-spec.png');
  background-size: contain;
  background-repeat: no-repeat;

  .board__feature__text {
    @apply flex flex-1 text-white text-[26px] leading-[26px] font-paytone;
  }
}

.board__feature__mobile {
  @apply mb-[8px] h-[48px] w-[200px] pl-[6px] flex items-center;

  background-image: url('../../../../assets/images/button-menu-spec.png');
  background-size: cover;

  .board__feature__text {
    @apply w-[50px] ml-[5px] text-white text-[12px] leading-[12px] font-paytone;
  }
}

.board__tooltip {
  :global {
    .ant-tooltip-inner {
      @apply rounded-[16px] max-w-[480px] shadow-[0_6px_12px_0_rgba(0,0,0,0.60)];
    }
  }
}
