.game {
  @apply w-full h-full flex items-center bg-inherit;

  background-size: cover;

  .game__pc__side {
    @apply relative flex-1 flex justify-center items-center h-full;
  }

  .game__pc__blur {
    @apply absolute z-[20] top-0 left-0 w-full h-full bg-no-repeat bg-[auto_100%] blur-[10px];
  }

  .game__pc__blur__right {
    @apply bg-right;
  }

  .game__content {
    @apply bg-inherit;
  }

  .scrollbar::-webkit-scrollbar {
    @apply !w-0;
  }
}
