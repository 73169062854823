/* stylelint-disable number-max-precision */

.leaderBoardMobileModal {
  :global {
    .ant-modal-body {
      @apply !pt-[16px];
    }
  }
}

.leaderBoardPcModal {
  :global {
    .ant-modal-body {
      @apply !pt-[2rem];
    }
  }
}

.leaderBoardModal {
  @apply relative  p-0;
  :global {
    .ant-modal-content {
      @apply h-full bg-inherit shadow-none rounded-[32px];
      .ant-modal-close {
        @apply absolute top-1 right-1;
        .ant-modal-close-x {
          @apply w-full h-full;
        }
      }
    }
  }
}

.scrollbar::-webkit-scrollbar {
  @apply !w-0;
}
