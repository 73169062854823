.awakenWrap {
  margin-bottom: 24px;
  :global {
    .swap-input-wrapper .swap-input-row2 .select-token-wrap {
      background-color: #a15a1c;
    }
    .swap-input-wrapper {
      border: 1px solid #a15a1c;
    }
    .swap-input-wrapper .swap-input-row2 {
      .select-token-wrap,
      .show-token-wrap {
        pointer-events: none;
      }
      .show-token-wrap {
        background-color: #f7edd5;
        .portkey-swap-common-svg {
          display: none;
        }
      }
    }
    .swap-input-wrapper .swap-input-row3 .show-max {
      border-color: #953d22;
      color: #953d22;
    }
    .swap-panel-wrapper .swap-token-switch-wrap .swap-token-switch-btn {
      display: none;
    }
    .swap-panel-wrapper .swap-btn-wrap .common-button {
      @apply bg-[var(--confirm-btn-bg)] text-center font-paytone text-xl leading-[48px] text-white;
      span {
        @apply font-paytone;
      }

      box-shadow: 0 3px 1px 0 #d18a4c inset, 0 6px 0 0 #773e0c;
      text-shadow: 0 0 1px #953d22;

      &:hover {
        @apply bg-[var(--confirm-btn-bg)] text-white;
      }
      &.btn-error {
        @apply bg-[#E74B44];
        &:hover {
          @apply bg-[#E74B44] text-white;
        }
      }
      &.btn-tip {
        @apply bg-[#ACA9A0] opacity-100;
        &:hover {
          @apply bg-[#ACA9A0] text-white;
        }
      }
    }
    .swap-panel-wrapper .swap-price-swap-info .icon-edit-icon {
      path {
        fill: #773e0c;
      }
      .awk-common-tool-tip {
        &.ant-tooltip-open {
          .common-question {
            background-image: none;
          }
        }
      }
    }
    .swap-price-swap-info {
      .common-tooltip {
        padding-bottom: 0;

        .portkey-trade-ant-tooltip-arrow {
          &::before {
            background-color: #e8d1ae;
          }
        }
        .portkey-trade-ant-tooltip-inner {
          background-color: #e8d1ae;
        }
      }
    }

    .portkey-trade-ant-modal,
    .portkey-trade-ant-modal-content,
    .portkey-trade-ant-modal-body {
      border-radius: 8px !important;
    }

    .portkey-trade-ant-modal-body {
      overflow: hidden !important;
    }
  }
}
