.button-mobile {
  @apply mt-0 fixed bottom-0 left-0 right-0 z-50;

  .button__border {
    @apply w-full h-[106px] bg-[100%_100%] bg-no-repeat;

    background-image: url('../../../../assets/images/goButton/go-btn-border.png');
    background-size: cover;
  }
  .dice-number-mobile {
    @apply w-[71px] h-[71px] flex justify-center absolute right-[-76px] top-[24px] z-[51] bg-[100%_100%] bg-no-repeat;

    background-size: cover;
  }

  .get-chance-mobile {
    @apply w-[71px] h-[71px] flex justify-center absolute left-[-58px] top-[24px] z-[51] bg-[100%_100%] bg-no-repeat;

    background-size: cover;
  }

  .purchase-text-mobile {
    -webkit-text-stroke: 0.5px #52300b !important;
  }

  .dice-content-mobile-mask {
    @apply absolute w-[65px] h-[50px] ml-[8px] mt-[1px] rounded-[18px] z-[10];

    background-color: rgb(82 48 11 / 30%);
  }

  .dice-content-mobile {
    @apply w-[44px] h-[44px] ml-[2px];
  }

  .dice-content-mobile-press {
    @apply mt-[3px];
  }

  .button__icon {
    @apply relative z-50;

    .button__icon__loading {
      @apply aspect-[1/1] relative;

      .button__icon__loading__bounce1,
      .button__icon__loading__bounce2 {
        @apply w-full h-full rounded-full bg-[#fff] opacity-[0.6] absolute top-0 left-0;

        animation: loading-bounce 2s infinite ease-in-out;
      }

      .button__icon__loading__bounce2 {
        animation-delay: -1s;
      }
    }
    svg {
      @apply w-full h-auto;
    }
  }
}

.btn-mobile {
  @apply bg-no-repeat bg-contain w-[150px] h-[120px] z-[50];

  .btn-mobile-mask {
    @apply absolute w-[130px] h-[100px] top-0 left-[-14px] rounded-[38px] z-[10];

    background-color: rgb(82 48 11 / 30%);
  }
}

.btn-pc {
  @apply bg-[100%_100%] bg-no-repeat bg-cover w-[284px]  h-[158px];

  .btn-pc-mask {
    @apply absolute w-[242px] h-[122px] top-[0px] left-[32px] rounded-[48px] z-[10];

    background-color: rgb(82 48 11 / 30%);
    background-size: cover;
  }
}

.hop-btn-pc-default {
  background-image: url('../../../../assets/images/goButton/hop-default.png');
}

.hop-btn-pc-pressed {
  background-image: url('../../../../assets/images/goButton/hop-pressed.png');
}

.hop-btn-pc-hover {
  background-image: url('../../../../assets/images/goButton/hop-hover.png');
}

.hop-btn-pc-disabled {
  background-image: url('../../../../assets/images/goButton/hop-disable.png');
}

.button {
  /* @apply pb-[78px] mt-0 w-[398px] h-[142px]; */
  @apply pb-[78px] mt-0 flex;
  .button__icon {
    @apply relative z-50;
    svg {
      @apply w-full h-auto;
    }
    .button__icon__loading {
      @apply aspect-[1/1] relative;

      .button__icon__loading__bounce1,
      .button__icon__loading__bounce2 {
        @apply w-full h-full rounded-full bg-[#fff] opacity-[0.6] absolute top-0 left-0;

        animation: loading-bounce 2s infinite ease-in-out;
      }

      .button__icon__loading__bounce2 {
        animation-delay: -1s;
      }
    }
  }
  .dice-number {
    @apply w-[149px] h-[153px] bg-[100%_100%] bg-no-repeat flex-shrink-0 relative;

    background-size: cover;
  }

  .dice-content-mask {
    @apply w-[115px] h-[124px] absolute top-[10px] right-[11px] rounded-[42px] z-[10];

    background-color: rgb(82 48 11 / 30%);
  }

  .dice-content {
    @apply w-[105px] h-[100px] absolute top-[6px] right-[16px];
  }

  .dice-content-press {
    @apply top-[16px];
  }

  .dice-number:not(:first-child) {
    @apply ml-[-8px];
  }

  @keyframes loading-bounce {
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
}
