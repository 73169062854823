.pcDepositWrap {
  @apply px-0 pb-0;
  :global {
    .etransfer-ui-deposit-title {
      @apply hidden;
    }

    .etransfer-ui-selected-data-wrapper,
    .calculator-header-title,
    .receive-amount,
    .deposit-address-label {
      @apply text-[#1A1A1A];
    }

    .selected-token,
    .selected-chain {
      @apply !bg-[#fff];
    }

    .selected-token,
    .selected-chain,
    .you-pay-main,
    .deposit-address-wrapper,
    .etransfer-ui-deposit-retry {
      @apply !border-[1px] !border-solid !border-[#A15A1C];
    }

    .etransfer-ui-deposit-tip {
      @apply !bg-[#E8D1AE] !border-[#953D22];
    }

    .etransfer-ui-description-wrapper {
      @apply bg-[#E8D1AE] border-0;
    }

    .etransfer-ui-calculator {
      @apply bg-[#E8D1AE];
    }

    .etransfer-ui-selected-data-wrapper .etransfer-ui-selected-token-wrapper:not(:first-child),
    .etransfer-ui-selected-data-wrapper .etransfer-ui-selected-chain-wrapper:not(:first-child),
    .etransfer-ui-selected-data-wrapper .etransfer-ui-selected-token-wrapper .etransfer-ui-dynamic-arrow,
    .etransfer-ui-selected-data-wrapper .space,
    .etransfer-ui-row-center,
    .etransfer-ui-description-wrapper {
      display: none !important;
    }

    .etransfer-ui-deposit-select-token {
      pointer-events: none;
    }
    .etransfer-ui-check-txn-btn {
      @apply bg-[#9a531f] border-0;
    }
  }
}

.mobileDepositWrap {
  @apply !p-0;

  :global {
    .etransfer-ui-common-svg {
      svg {
        @apply !h-auto !w-auto;
      }
    }

    .calculator-header-title,
    .receive-amount {
      @apply text-[#1A1A1A];
    }

    .etransfer-ui-deposit-select-token-network,
    .etransfer-ui-deposit-select-token-chain {
      @apply bg-[#fff] !border-[1px] !border-solid !border-[#A15A1C];
    }

    .etransfer-ui-calculator {
      @apply bg-[#E8D1AE];

      .etransfer-ui-calculator-content {
        @apply flex-col;
      }
    }

    .you-pay-main {
      @apply !border-[rgba(161,90,28,0.40)];
    }

    #etransfer-ui-deposit-select-token-result .etransfer-ui-dynamic-arrow,
    .etransfer-ui-deposit-select-token-chain,
    .etransfer-ui-description-wrapper {
      display: none !important;
    }

    .etransfer-ui-deposit-select-token {
      pointer-events: none;
    }

    .etransfer-ui-deposit-tip {
      background-color: transparent;
      border-color: #fff;
    }

    .etransfer-ui-exchange-rate .etransfer-ui-flex-row-center {
      align-items: start;
    }

    .etransfer-ui-exchange-rate div:last-child {
      text-align: end;
      min-width: 80px;
    }

    .etransfer-ui-check-txn-btn {
      @apply bg-[#9a531f] border-0;
    }
  }
}
