.inputForm {
  :global {
    .web2design-qrcode-icon,
    .divider-center.portkey-ant-divider-with-text,
    .social-icon-group-wrapper {
      @apply hidden;
    }

    .web2design-qrcode-icon,
    .divider-center.portkey-ant-divider-with-text,
    .login-primary-btn {
      @apply mb-[24px];
    }

    .backleft-icon {
      @apply hidden;
    }
  }
}

.qrcodeBox {
  :global {
    .back-icon-wrapper {
      @apply !hidden;
    }

    .backleft-icon {
      @apply !hidden;
    }
  }
}

.loginContainer {
  @apply flex h-full w-full flex-col items-center justify-end  !bg-cover bg-center bg-no-repeat px-10 text-base text-white  md:justify-start md:pt-[46.7vh];
  .more {
    @apply z-20 pb-8 font-paytone underline md:pb-0 text-[20px] md:text-[32px] text-white;
  }
  .loginBtn,
  .unlockBtn,
  .loginBtnYellow {
    @apply relative flex items-center z-20 mb-6 h-[3rem] w-full justify-center rounded-full bg-[#5D42FF] px-5 font-paytone md:h-[82px] md:w-[450px] md:justify-end md:rounded-[38px] md:px-8 text-[20px] md:text-[32px] md:leading-6;

    box-shadow: 0 3px 1px 0 #6f6cff inset, 0 4px 0 0 #1d089b;
    text-shadow: 0 0 1px #4b35d0;
  }
  .unlockBtn {
    @apply h-11 rounded-[22px] md:h-[70px] md:rounded-[35px] mb-[94px] bg-[#A15A1C] md:mb-0 md:mt-[3.7vh] md:justify-center;

    box-shadow: 0 3px 1px 0 #d18a4c inset, 0 6px 0 0 #773E0C;
    text-shadow: 0 0 1px #953d22;
  }
  .loginBtnYellow {
    @apply bg-[#00A6EF];

    box-shadow: 0 3px 1px 0 #29bdff inset, 0 4px 0 0 #005e88;
    text-shadow: 0 0 1px #0090d0;
  }
}

.loginBtnIcon,
.loginBtnBlueIcon {
  @apply text-2xl md:left-8 md:text-[44px] flex items-center;
  path {
    @apply fill-white;
  }
}

.loginMethodModal {
  @apply !w-[750px] rounded-[32px];
  :global {
    .ant-modal-content {
      @apply !rounded-[32px];
    }
    .ant-modal-body {
      @apply !px-[32px] !py-[48px] text-[32px] leading-6 border-[16px] border-[#9A531F] rounded-[32px];
    }
  }
  .loginBtn {
    @apply relative !mb-5 mx-[97px] !h-[77px] flex items-center !justify-center !rounded-[39px] !px-10 bg-[var(--confirm-btn-bg)] font-paytone text-white;
  }
  .drawerHeader {
    @apply !mb-[40px] text-center font-paytone !text-[32px] leading-6 text-[#953D22];
  }
  .drawer__close {
    @apply absolute !right-[8Px] !top-[8Px] !text-[30px] w-[48px] h-[48px];
  }
  .loginBtnBlueIcon {
    @apply left-[44.7px];
    path {
      @apply fill-white;
    }
  }
}

.loginMethodDrawer {
  .loginBtn {
    @apply relative text-[20px] md:text-[32px] pl-4 mb-4 flex h-[3rem] items-center justify-center rounded-[36px] bg-[var(--confirm-btn-bg)] font-paytone text-white;
  }
}

.loginMethodDrawer,
.loginMethodModal {
  @apply text-base;
  :global {
    .ant-drawer-header,
    .ant-modal-header,
    .ant-modal-footer {
      @apply hidden;
    }
    .ant-drawer-content {
      @apply rounded-tl-2xl rounded-tr-2xl;
    }
    .ant-modal-content {
      @apply overflow-auto rounded-2xl bg-[var(--color-main)] text-base  text-white;
    }
    .ant-drawer-wrapper-body {
      @apply overflow-auto bg-[var(--color-main)] text-base  text-white;
    }
    .ant-drawer-body,
    .ant-modal-body {
      @apply relative px-10 pb-6;
    }
    .ant-drawer-content-wrapper {
      @apply min-h-[392px];
    }
  }

  .loginBtn {
    box-shadow: 0 3px 1px 0 #d18a4c inset, 0 6px 0 0 #773E0C;
    text-shadow: 0 0 1px #953d22;
  }
  .drawerHeader {
    @apply mb-8 text-center font-paytone text-2xl leading-6 text-[#953D22];
  }
  .drawer__close {
    @apply absolute right-3 top-3 w-[28px] md:w-[48px];
    svg {
      @apply w-6 h-6;
    }
  }
}
.unlock_footer_text {
  color: #55585E;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 20px;
  margin-left: 14px;
  margin-right: 14px;
  .unlock_footer_highlight {
    color: #5D42FF;
  }
}
