/* stylelint-disable number-max-precision */
.getMoreACORNSModal {
  @apply !px-0 overflow-hidden relative;

  :global {
    .ant-modal-content {
      .ant-modal-close {
        @apply absolute top--1 right-1;
        .ant-modal-close-x {
          @apply w-full h-full;
        }
      }
      .ant-modal-body {
        @apply pt-0 leading-6 rounded-[16px];
      }
    }
  }
}

.showBeanPassModal {
  @apply !w-[358px] md:!w-[580px];
}

.getBeanPassModal {
  @apply !w-[358px] md:!w-[750px];
  :global(.ant-modal-content) {
    @apply md:rounded-[20px];
    .confirmBtn {
      @apply mx-auto md:h-[77.5px] md:rounded-[38.75px] md:text-[32px] md:leading-[77.5px];
    }
  }
}

.settingModal {
  :global(.ant-modal-body) {
    @apply !pb-[27px] md:!pb-[61.5px];
    .modal__title {
      @apply md:!h-[118px] md:!text-[32px] md:!leading-6;
    }
  }
  :global(.ant-modal-content) {
    @apply md:!w-[580px] md:rounded-[20px];
  }
}

.assetModal {
  :global(.ant-modal-footer) {
    @apply hidden;
  }
}
