.headerContainer {
  @apply flex flex-col h-[100px] w-full  px-[12px] py-[8px] gap-[4px] bg-[#A15A1C];

  background-image: url('../../assets/images/headerMenu/top-menu-bg-m.png');
  background-size: cover;

  .header__menu {
    @apply flex w-full items-center;
  }
}

.board__acorn {
  @apply pl-1 h-[38px] w-[158px] flex items-center;

  background-image: url('../../assets/images/button-menu-default-s.png');
  background-size: cover;

  .board__acorn__number {
    @apply pl-[12px] flex flex-wrap break-all flex-1 text-[#953D22] text-[16px] leading-[16px] font-paytone;
  }
}

.board__tooltip {
  :global {
    .ant-tooltip-inner {
      @apply rounded-[16px];
    }
  }
}
