.Recreation_game___x61D {

  display: flex;

  height: 100%;

  width: 100%;

  align-items: center;

  background-color: inherit;

  background-size: cover;
}

  .Recreation_game___x61D .Recreation_game__pc__side__1NR33 {

  position: relative;
}

  .Recreation_game___x61D .Recreation_game__pc__side__1NR33 {

  display: flex;
}

  .Recreation_game___x61D .Recreation_game__pc__side__1NR33 {

  height: 100%;
}

  .Recreation_game___x61D .Recreation_game__pc__side__1NR33 {

  flex: 1 1 0%;
}

  .Recreation_game___x61D .Recreation_game__pc__side__1NR33 {

  align-items: center;
}

  .Recreation_game___x61D .Recreation_game__pc__side__1NR33 {

  justify-content: center;
}

  .Recreation_game___x61D .Recreation_game__pc__blur__tZhnZ {

  position: absolute;
}

  .Recreation_game___x61D .Recreation_game__pc__blur__tZhnZ {

  top: 0px;
}

  .Recreation_game___x61D .Recreation_game__pc__blur__tZhnZ {

  left: 0px;
}

  .Recreation_game___x61D .Recreation_game__pc__blur__tZhnZ {

  z-index: 20;
}

  .Recreation_game___x61D .Recreation_game__pc__blur__tZhnZ {

  height: 100%;
}

  .Recreation_game___x61D .Recreation_game__pc__blur__tZhnZ {

  width: 100%;
}

  .Recreation_game___x61D .Recreation_game__pc__blur__tZhnZ {

  background-size: auto 100%;
}

  .Recreation_game___x61D .Recreation_game__pc__blur__tZhnZ {

  background-repeat: no-repeat;
}

  .Recreation_game___x61D .Recreation_game__pc__blur__tZhnZ {

  --tw-blur: blur(0.625rem);

  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

  .Recreation_game___x61D .Recreation_game__pc__blur__right__I6OVX {

  background-position: right;
}

  .Recreation_game___x61D .Recreation_game__content__bN7zC {

  background-color: inherit;
}

  .Recreation_game___x61D .Recreation_scrollbar__XnGHv::-webkit-scrollbar {

  width: 0px !important;
}

.Lattice_lattice__Vd3mV >div {

    background-size: cover;

    background-repeat: no-repeat;
}

.Lattice_lattice__Vd3mV.Lattice_lattice--shadow__m_dMq {

    background-size: cover;

    background-repeat: no-repeat;

    --tw-shadow: -0.5rem 1px 0.25rem 0 #00000052;

    --tw-shadow-colored: -0.5rem 1px 0.25rem 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Lattice_lattice__Vd3mV .Lattice_lattice__arrow__CAhNd {

    position: absolute;

    left: 0px;

    right: 0px;

    top: 30%;

    margin: auto;

    display: flex;

    height: 1rem;

    width: 1rem;

    align-items: center;

    justify-content: center;

    font-size: 1rem;
}

.Lattice_lattice__Vd3mV .Lattice_lattice__arrow__CAhNd > svg {

    height: 1rem;
}

.Lattice_lattice__Vd3mV .Lattice_lattice__arrow__CAhNd > svg >path {

    stroke: inherit;
}

.Lattice_lattice-mobile__jdL_g .Lattice_lattice__arrow__CAhNd {

    height: 1.75rem;

    width: 1.75rem;
}

.Lattice_lattice-mobile__jdL_g .Lattice_lattice__arrow__CAhNd > svg {

    height: 1.75rem;
}

.Lattice_lattice-mobile__jdL_g .Lattice_lattice__arrow__CAhNd > svg >path {

    stroke: inherit;
}



.Role_appears-animation__aIAPD {
  animation: Role_appears-animation__aIAPD 2.5s;
}

.Role_appears-animation-right__HKGn3 {
  animation: Role_appears-animation-right__HKGn3 2.5s;
}

.Role_appears-animation-left__0EMNU {
  animation: Role_appears-animation-left__0EMNU 2.5s;
}

@keyframes Role_appears-animation__aIAPD
{
  0%, 100% {
    transform: scale(0);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  40% {
    transform: scale(1);
    top: 0;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
  50% {
    transform: scale(1);
    top: 0;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
  60% {
    transform: scale(1);
    top: 0;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes Role_appears-animation-right__HKGn3
{
  0%, 100% {
    transform: scale(0);
    left: 40%;
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  40% {
    transform: scale(1);
    left: 90%;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
  50% {
    transform: scale(1);
    left: 90%;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
  60% {
    transform: scale(1);
    left: 90%;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes Role_appears-animation-left__0EMNU
{
  0%, 100% {
    transform: scale(0);
    right: 40%;
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  40% {
    transform: scale(1);
    right: 90%;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
  50% {
    transform: scale(1);
    right: 90%;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
  60% {
    transform: scale(1);
    right: 90%;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.Role_role__KOUIa {
  position: absolute;
  top: -120%;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 0.75rem;
}

.Role_role__KOUIa > svg {
  width: auto;
}

.GoButton_button-mobile__GsKJS {

    position: fixed;

    bottom: 0px;

    left: 0px;

    right: 0px;

    z-index: 50;

    margin-top: 0px;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__border__WwCP1 {

    height: 6.625rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__border__WwCP1 {

    width: 100%;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__border__WwCP1 {

    background-position: 100% 100%;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__border__WwCP1 {

    background-repeat: no-repeat;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__border__WwCP1 {

    background-image: url(/_next/static/media/go-btn-border.2abd3748.png);
    background-size: cover;
  }

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    position: absolute;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    right: -4.75rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    top: 1.5rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    z-index: 51;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    display: flex;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    height: 4.4375rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    width: 4.4375rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    justify-content: center;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    background-position: 100% 100%;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    background-repeat: no-repeat;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-number-mobile__6oVmY {

    background-size: cover;
  }

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    position: absolute;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    left: -3.625rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    top: 1.5rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    z-index: 51;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    display: flex;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    height: 4.4375rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    width: 4.4375rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    justify-content: center;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    background-position: 100% 100%;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    background-repeat: no-repeat;
}

  .GoButton_button-mobile__GsKJS .GoButton_get-chance-mobile__ie4s1 {

    background-size: cover;
  }

  .GoButton_button-mobile__GsKJS .GoButton_purchase-text-mobile__nGRUV {
    -webkit-text-stroke: 0.5px #52300b !important;
  }

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile-mask__ZfCLn {

    position: absolute;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile-mask__ZfCLn {

    z-index: 10;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile-mask__ZfCLn {

    margin-left: 0.5rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile-mask__ZfCLn {

    margin-top: 1px;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile-mask__ZfCLn {

    height: 3.125rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile-mask__ZfCLn {

    width: 4.0625rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile-mask__ZfCLn {

    border-radius: 1.125rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile-mask__ZfCLn {

    background-color: rgb(82 48 11 / 30%);
  }

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile__ttS4l {

    margin-left: 0.125rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile__ttS4l {

    height: 2.75rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile__ttS4l {

    width: 2.75rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_dice-content-mobile-press__THADe {

    margin-top: 0.1875rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx {

    position: relative;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx {

    z-index: 50;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj {

    position: relative;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj {

    aspect-ratio: 1/1;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    position: absolute;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    top: 0px;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    left: 0px;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    height: 100%;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    width: 100%;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    border-radius: 624.9375rem;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    opacity: 0.6;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

        animation: GoButton_loading-bounce__tBSuW 2s infinite ease-in-out;
      }

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {
        animation-delay: -1s;
      }

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx svg {

    height: auto;
}

  .GoButton_button-mobile__GsKJS .GoButton_button__icon__4B4Dx svg {

    width: 100%;
}

.GoButton_btn-mobile__NFy0Q {

    z-index: 50;

    height: 7.5rem;

    width: 9.375rem;

    background-size: contain;

    background-repeat: no-repeat;
}

.GoButton_btn-mobile__NFy0Q .GoButton_btn-mobile-mask__SiGRO {

    position: absolute;
}

.GoButton_btn-mobile__NFy0Q .GoButton_btn-mobile-mask__SiGRO {

    top: 0px;
}

.GoButton_btn-mobile__NFy0Q .GoButton_btn-mobile-mask__SiGRO {

    left: -0.875rem;
}

.GoButton_btn-mobile__NFy0Q .GoButton_btn-mobile-mask__SiGRO {

    z-index: 10;
}

.GoButton_btn-mobile__NFy0Q .GoButton_btn-mobile-mask__SiGRO {

    height: 6.25rem;
}

.GoButton_btn-mobile__NFy0Q .GoButton_btn-mobile-mask__SiGRO {

    width: 8.125rem;
}

.GoButton_btn-mobile__NFy0Q .GoButton_btn-mobile-mask__SiGRO {

    border-radius: 2.375rem;
}

.GoButton_btn-mobile__NFy0Q .GoButton_btn-mobile-mask__SiGRO {

    background-color: rgb(82 48 11 / 30%);
  }

.GoButton_btn-pc__0ILmV {

    height: 9.875rem;

    width: 17.75rem;

    background-size: cover;

    background-position: 100% 100%;

    background-repeat: no-repeat;
}

.GoButton_btn-pc__0ILmV .GoButton_btn-pc-mask___2KOp {

    position: absolute;
}

.GoButton_btn-pc__0ILmV .GoButton_btn-pc-mask___2KOp {

    top: 0px;
}

.GoButton_btn-pc__0ILmV .GoButton_btn-pc-mask___2KOp {

    left: 2rem;
}

.GoButton_btn-pc__0ILmV .GoButton_btn-pc-mask___2KOp {

    z-index: 10;
}

.GoButton_btn-pc__0ILmV .GoButton_btn-pc-mask___2KOp {

    height: 7.625rem;
}

.GoButton_btn-pc__0ILmV .GoButton_btn-pc-mask___2KOp {

    width: 15.125rem;
}

.GoButton_btn-pc__0ILmV .GoButton_btn-pc-mask___2KOp {

    border-radius: 3rem;
}

.GoButton_btn-pc__0ILmV .GoButton_btn-pc-mask___2KOp {

    background-color: rgb(82 48 11 / 30%);
    background-size: cover;
  }

.GoButton_hop-btn-pc-default__TIZrV {
  background-image: url(/_next/static/media/hop-default.29aa0d31.png);
}

.GoButton_hop-btn-pc-pressed__Xtivf {
  background-image: url(/_next/static/media/hop-pressed.c7d4d98b.png);
}

.GoButton_hop-btn-pc-hover__q_UwY {
  background-image: url(/_next/static/media/hop-hover.a990555f.png);
}

.GoButton_hop-btn-pc-disabled__OXh8l {
  background-image: url(/_next/static/media/hop-disable.f388078b.png);
}

.GoButton_button__onkbH {
  /* @apply pb-[78px] mt-0 w-[398px] h-[142px]; */
  margin-top: 0px;
  display: flex;
  padding-bottom: 4.875rem;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx {

    position: relative;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx {

    z-index: 50;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx svg {

    height: auto;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx svg {

    width: 100%;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj {

    position: relative;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj {

    aspect-ratio: 1/1;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    position: absolute;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    top: 0px;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    left: 0px;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    height: 100%;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    width: 100%;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    border-radius: 624.9375rem;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

    opacity: 0.6;
}

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce1__GRKJd,
      .GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {

        animation: GoButton_loading-bounce__tBSuW 2s infinite ease-in-out;
      }

.GoButton_button__onkbH .GoButton_button__icon__4B4Dx .GoButton_button__icon__loading__HTmbj .GoButton_button__icon__loading__bounce2__IJ42B {
        animation-delay: -1s;
      }

.GoButton_button__onkbH .GoButton_dice-number__FegB6 {

    position: relative;
}

.GoButton_button__onkbH .GoButton_dice-number__FegB6 {

    height: 9.5625rem;
}

.GoButton_button__onkbH .GoButton_dice-number__FegB6 {

    width: 9.3125rem;
}

.GoButton_button__onkbH .GoButton_dice-number__FegB6 {

    flex-shrink: 0;
}

.GoButton_button__onkbH .GoButton_dice-number__FegB6 {

    background-position: 100% 100%;
}

.GoButton_button__onkbH .GoButton_dice-number__FegB6 {

    background-repeat: no-repeat;
}

.GoButton_button__onkbH .GoButton_dice-number__FegB6 {

    background-size: cover;
  }

.GoButton_button__onkbH .GoButton_dice-content-mask__F_KXD {

    position: absolute;
}

.GoButton_button__onkbH .GoButton_dice-content-mask__F_KXD {

    top: 0.625rem;
}

.GoButton_button__onkbH .GoButton_dice-content-mask__F_KXD {

    right: 0.6875rem;
}

.GoButton_button__onkbH .GoButton_dice-content-mask__F_KXD {

    z-index: 10;
}

.GoButton_button__onkbH .GoButton_dice-content-mask__F_KXD {

    height: 7.75rem;
}

.GoButton_button__onkbH .GoButton_dice-content-mask__F_KXD {

    width: 7.1875rem;
}

.GoButton_button__onkbH .GoButton_dice-content-mask__F_KXD {

    border-radius: 2.625rem;
}

.GoButton_button__onkbH .GoButton_dice-content-mask__F_KXD {

    background-color: rgb(82 48 11 / 30%);
  }

.GoButton_button__onkbH .GoButton_dice-content__O2uao {

    position: absolute;
}

.GoButton_button__onkbH .GoButton_dice-content__O2uao {

    top: 0.375rem;
}

.GoButton_button__onkbH .GoButton_dice-content__O2uao {

    right: 1rem;
}

.GoButton_button__onkbH .GoButton_dice-content__O2uao {

    height: 6.25rem;
}

.GoButton_button__onkbH .GoButton_dice-content__O2uao {

    width: 6.5625rem;
}

.GoButton_button__onkbH .GoButton_dice-content-press__Hk65R {

    top: 1rem;
}

.GoButton_button__onkbH .GoButton_dice-number__FegB6:not(:first-child) {

    margin-left: -0.5rem;
}

@keyframes GoButton_loading-bounce__tBSuW {
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }

.Board_board__acorn__SuxVw {

  margin-bottom: 0.75rem;

  display: flex;

  height: 5rem;

  width: 25rem;

  align-items: center;

  padding-left: 1rem;

  background-image: url(/_next/static/media/button-menu-default.aab1d132.png);
  background-size: contain;
  background-repeat: no-repeat;
}

  .Board_board__acorn__SuxVw .Board_board__acorn__number__wU_js {

  display: flex;
}

  .Board_board__acorn__SuxVw .Board_board__acorn__number__wU_js {

  flex: 1 1 0%;
}

  .Board_board__acorn__SuxVw .Board_board__acorn__number__wU_js {

  justify-content: center;
}

  .Board_board__acorn__SuxVw .Board_board__acorn__number__wU_js {

  font-family: PaytoneOne, sans-serif;
}

  .Board_board__acorn__SuxVw .Board_board__acorn__number__wU_js {

  font-size: 2rem;
}

  .Board_board__acorn__SuxVw .Board_board__acorn__number__wU_js {

  line-height: 3rem;
}

  .Board_board__acorn__SuxVw .Board_board__acorn__number__wU_js {

  --tw-text-opacity: 1;

  color: rgb(149 61 34 / var(--tw-text-opacity));
}
.Board_board__feature__lM4Wr {

  margin-top: 1.5rem;

  margin-bottom: 0.75rem;

  display: flex;

  height: 6rem;

  width: 25rem;

  align-items: center;

  padding-left: 1rem;
  
  background-image: url(/_next/static/media/button-menu-spec.6226cdee.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.Board_board__feature__lM4Wr .Board_board__feature__text__rd9VM {

  display: flex;
}
.Board_board__feature__lM4Wr .Board_board__feature__text__rd9VM {

  flex: 1 1 0%;
}
.Board_board__feature__lM4Wr .Board_board__feature__text__rd9VM {

  font-family: PaytoneOne, sans-serif;
}
.Board_board__feature__lM4Wr .Board_board__feature__text__rd9VM {

  font-size: 1.625rem;
}
.Board_board__feature__lM4Wr .Board_board__feature__text__rd9VM {

  line-height: 1.625rem;
}
.Board_board__feature__lM4Wr .Board_board__feature__text__rd9VM {

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Board_board__feature__mobile__lQHDf {

  margin-bottom: 0.5rem;

  display: flex;

  height: 3rem;

  width: 12.5rem;

  align-items: center;

  padding-left: 0.375rem;

  background-image: url(/_next/static/media/button-menu-spec.6226cdee.png);
  background-size: cover;
}

.Board_board__feature__mobile__lQHDf .Board_board__feature__text__rd9VM {

  margin-left: 0.3125rem;
}

.Board_board__feature__mobile__lQHDf .Board_board__feature__text__rd9VM {

  width: 3.125rem;
}

.Board_board__feature__mobile__lQHDf .Board_board__feature__text__rd9VM {

  font-family: PaytoneOne, sans-serif;
}

.Board_board__feature__mobile__lQHDf .Board_board__feature__text__rd9VM {

  font-size: 0.75rem;
}

.Board_board__feature__mobile__lQHDf .Board_board__feature__text__rd9VM {

  line-height: 0.75rem;
}

.Board_board__feature__mobile__lQHDf .Board_board__feature__text__rd9VM {

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Board_board__tooltip__Q0gD2 .ant-tooltip-inner {

  max-width: 30rem;
}

.Board_board__tooltip__Q0gD2 .ant-tooltip-inner {

  border-radius: 1rem;
}

.Board_board__tooltip__Q0gD2 .ant-tooltip-inner {

  --tw-shadow: 0 0.375rem 0.75rem 0 rgba(0,0,0,0.60);

  --tw-shadow-colored: 0 0.375rem 0.75rem 0 var(--tw-shadow-color);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.RecreationModal_recreation-modal__3FKN4 {

  top: 0px;

  z-index: 1001;

  margin: 0px !important;

  display: flex;

  height: 100%;

  width: 100% !important;

  max-width: 100% !important;

  align-items: center;

  justify-content: center;

  overflow: hidden;

  padding: 0px !important;
}

  .RecreationModal_recreation-modal__3FKN4 .ant-modal-content {

  width: 100%;
}

  .RecreationModal_recreation-modal__3FKN4 .ant-modal-content {

  background-color: transparent !important;
}

  .RecreationModal_recreation-modal__3FKN4 .ant-modal-content {

  --tw-shadow: 0 0 #0000 !important;

  --tw-shadow-colored: 0 0 #0000 !important;

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.RecreationModal_treasure-btn__hPUNM {

  display: flex;

  align-items: center;

  justify-content: center;

  border-radius: 624.9375rem;

  background-position: 100% 100%;

  background-repeat: no-repeat;

  text-align: center;

  box-shadow: 0 0.125rem 0.125rem 0 rgb(0 0 0 / 25%);
  background-size: 100% 100%;
}
.RecreationModal_treasure-btn-pc__bIHwe {

  height: 4.875rem;

  width: 23.5625rem;

  font-size: 2rem;

  line-height: 1.5rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.RecreationModal_treasure-btn-mobile__bBigl {

  height: 3rem;

  width: 67%;

  font-size: 1.25rem;

  line-height: 1.5rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.RecreationModal_recreation-modal-mobile__JHEVB .ant-modal-body {

  padding: 0px;
}

:root {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-1: 9px;
  --adm-font-size-2: 10px;
  --adm-font-size-3: 11px;
  --adm-font-size-4: 12px;
  --adm-font-size-5: 13px;
  --adm-font-size-6: 14px;
  --adm-font-size-7: 15px;
  --adm-font-size-8: 16px;
  --adm-font-size-9: 17px;
  --adm-font-size-10: 18px;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-yellow: #ff9f18;
  --adm-color-orange: #ff6430;
  --adm-color-wathet: #e7f1ff;
  --adm-color-text: #333333;
  --adm-color-text-secondary: #666666;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-background: #ffffff;
  --adm-color-highlight: var(--adm-color-danger);
  --adm-color-white: #ffffff;
  --adm-color-box: #f5f5f5;
  --adm-color-text-light-solid: var(--adm-color-white);
  --adm-color-text-dark-solid: #000000;
  --adm-color-fill-content: var(--adm-color-box);
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue',
    helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
    'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --adm-border-color: var(--adm-color-border);
}
html[data-prefers-color-scheme='dark'] {
  --adm-color-primary: #3086ff;
  --adm-color-success: #34b368;
  --adm-color-warning: #ffa930;
  --adm-color-danger: #ff4a58;
  --adm-color-yellow: #ffa930;
  --adm-color-orange: #e65a2b;
  --adm-color-wathet: #0d2543;
  --adm-color-text: #e6e6e6;
  --adm-color-text-secondary: #b3b3b3;
  --adm-color-weak: #808080;
  --adm-color-light: #4d4d4d;
  --adm-color-border: #2b2b2b;
  --adm-color-box: #0a0a0a;
  --adm-color-background: #1a1a1a;
  --adm-color-background-body: var(--adm-color-background);
  --adm-border-color: var(--adm-color-border);
}
:root {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  background-color: var(--adm-color-background-body);
}
body {
  color: var(--adm-color-text);
  font-size: var(--adm-font-size-main);
  font-family: var(--adm-font-family);
}
a,
button {
  cursor: pointer;
}
a {
  color: var(--adm-color-primary);
  transition: opacity ease-in-out 0.2s;
}
a:active {
  opacity: 0.8;
}
.adm-plain-anchor {
  color: unset;
  transition: none;
}
.adm-plain-anchor:active {
  opacity: unset;
}
body.adm-overflow-hidden {
  overflow: hidden !important;
}
div.adm-px-tester {
  --size: 1;
  height: calc(var(--size) / 2 * 2px);
  width: 0;
  position: fixed;
  right: -100vw;
  bottom: -100vh;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  pointer-events: none;
}

.adm-dot-loading {
  display: inline-block;
}

.adm-infinite-scroll {
  color: var(--adm-color-weak);
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--adm-font-size-main);
}
.adm-infinite-scroll-failed-text {
  display: inline-block;
  margin-right: 8px;
}

.adm-list {
  --header-font-size: var(--adm-font-size-7);
  --prefix-width: 'auto';
  --prefix-padding-right: 12px;
  --align-items: center;
  --active-background-color: var(--adm-color-border);
  --border-inner: solid 1px var(--adm-color-border);
  --border-top: solid 1px var(--adm-color-border);
  --border-bottom: solid 1px var(--adm-color-border);
  --padding-left: 12px;
  --padding-right: 12px;
  --font-size: var(--adm-font-size-9);
  --extra-max-width: 70%;
}
.adm-list-header {
  color: var(--adm-color-weak);
  font-size: var(--header-font-size);
  padding: 8px var(--padding-right) 8px var(--padding-left);
}
.adm-list-body {
  background-color: var(--adm-color-background);
  overflow: hidden;
  font-size: var(--font-size);
}
.adm-list-body-inner {
  margin-top: -1px;
}
.adm-list-default .adm-list-body {
  border-top: var(--border-top);
  border-bottom: var(--border-bottom);
}
.adm-list-card {
  margin: 12px;
}
.adm-list-card .adm-list-body {
  border-radius: 8px;
}
.adm-list-card .adm-list-header {
  padding-left: 0;
}
.adm-list-item {
  display: block;
  padding-left: var(--padding-left);
  position: relative;
  background-color: var(--adm-color-background);
  line-height: 1.5;
}
.adm-list-item-title,
.adm-list-item-description {
  color: var(--adm-color-weak);
  font-size: var(--adm-font-size-main);
}
.adm-list-item-content {
  display: flex;
  align-items: var(--align-items);
  justify-content: flex-start;
  border-top: var(--border-inner);
  padding-right: var(--padding-right);
}
.adm-list-item-content-prefix {
  width: var(--prefix-width);
  flex: none;
  padding-right: var(--prefix-padding-right);
}
.adm-list-item-content-main {
  flex: auto;
  padding: 12px 0;
}
.adm-list-item-content-extra {
  flex: none;
  padding-left: 12px;
  font-size: var(--adm-font-size-7);
  color: var(--adm-color-weak);
  max-width: var(--extra-max-width);
}
.adm-list-item-content-arrow {
  flex: none;
  display: flex;
  align-items: center;
  margin-left: 4px;
  color: var(--adm-color-light);
  font-size: 19px;
}
.adm-list-item-disabled {
  cursor: not-allowed;
}
.adm-list-item-disabled.adm-list-item-disabled > .adm-list-item-content > * {
  opacity: 0.4;
  pointer-events: none;
}
a.adm-list-item:active:not(.adm-list-item-disabled) {
  background-color: var(--active-background-color);
}
a.adm-list-item:active:not(.adm-list-item-disabled)::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  border-bottom: var(--border-inner);
}

.style_commonBtn__NPldA {

  height: 3rem;

  border-radius: 624.9375rem;

  background-color: var(--confirm-btn-bg);

  text-align: center;

  font-family: PaytoneOne, sans-serif;

  font-size: 1.25rem;

  line-height: 1.75rem;

  line-height: 3rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));

  box-shadow: 0 0.1875rem 1px 0 #D18A4C inset, 0 0.375rem 0 0 #773E0C;
  text-shadow: 0 0 1px #953D22
}

.adm-passcode-input {
  position: relative;
  display: inline-block;
  --cell-size: 40px;
  --cell-gap: 6px;
  --dot-size: 10px;
  --border-color: var(--adm-color-border);
  --border-radius: 8px;
}
.adm-passcode-input-cell-container {
  display: inline-flex;
  vertical-align: top;
}
.adm-passcode-input-cell {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: var(--adm-font-size-10);
  width: var(--cell-size);
  height: var(--cell-size);
  background: var(--adm-color-background);
}
.adm-passcode-input:not(.adm-passcode-input-seperated) {
  border-radius: var(--border-radius);
  overflow: hidden;
  border: 1px solid var(--border-color);
}
.adm-passcode-input:not(.adm-passcode-input-seperated) .adm-passcode-input-cell:not(:last-child) {
  border-right: 1px solid var(--border-color);
}
.adm-passcode-input:not(.adm-passcode-input-seperated).adm-passcode-input-focused {
  border-color: var(--adm-color-primary);
  box-shadow: 0 0 2px 0 var(--adm-color-primary);
  outline: none;
}
.adm-passcode-input:not(.adm-passcode-input-seperated).adm-passcode-input-error {
  border-color: var(--adm-color-danger);
  box-shadow: 0 0 2px 0 var(--adm-color-danger);
  animation: 100ms ease-in-out 0s 3 normal none running adm-shake-horizontal;
}
.adm-passcode-input.adm-passcode-input-seperated .adm-passcode-input-cell {
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
}
.adm-passcode-input.adm-passcode-input-seperated .adm-passcode-input-cell:not(:last-child) {
  margin-right: var(--cell-gap);
}
.adm-passcode-input.adm-passcode-input-seperated .adm-passcode-input-cell-focused {
  border-color: var(--adm-color-primary);
  box-shadow: 0 0 2px 0 var(--adm-color-primary);
}
.adm-passcode-input-seperated.adm-passcode-input-focused {
  outline: none;
}
.adm-passcode-input.adm-passcode-input-seperated.adm-passcode-input-error {
  animation: 100ms ease-in-out 0s 3 normal none running adm-shake-horizontal;
}
.adm-passcode-input.adm-passcode-input-seperated.adm-passcode-input-error .adm-passcode-input-cell {
  border-color: var(--adm-color-danger);
  box-shadow: 0 0 2px 0 var(--adm-color-danger);
}
.adm-passcode-input-cell-dot::before {
  content: '';
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 100%;
  background: var(--adm-color-text);
}
.adm-passcode-input-cell-caret::after {
  content: '';
  width: 2px;
  height: 1.1em;
  margin-left: 1px;
  background: var(--adm-color-primary);
  animation: 1s linear infinite adm-caret-blink;
}
.adm-passcode-input > .adm-passcode-input-native-input {
  position: absolute;
  left: -200vw;
  top: 0;
  display: block;
  width: 50px;
  height: 20px;
  opacity: 0.5;
}
@keyframes adm-shake-horizontal {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-4px);
  }
  20% {
    transform: translateX(5px);
  }
  30% {
    transform: translateX(-1px);
  }
  40% {
    transform: translateX(8px);
  }
  50% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(-8px);
  }
  70% {
    transform: translateX(1px);
  }
  80% {
    transform: translateX(-5px);
  }
  90% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes adm-caret-blink {
  from {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

.adm-safe-area {
  --multiple: var(--adm-safe-area-multiple, 1);
  display: block;
  width: 100%;
}
.adm-safe-area-position-top {
  padding-top: calc(env(safe-area-inset-top) * var(--multiple));
}
.adm-safe-area-position-bottom {
  padding-bottom: calc(env(safe-area-inset-bottom) * var(--multiple));
}

.ShowPageLoading_page-modal__PqY_4 {

    top: 0px !important;

    z-index: 1001;

    margin: 0px !important;

    display: flex;

    height: 100%;

    width: 100% !important;

    max-width: 100% !important;

    align-items: center;

    justify-content: center;

    overflow: hidden;

    padding: 0px !important;
}

  .ShowPageLoading_page-modal__PqY_4 .ant-modal-content {

    background-color: transparent !important;
}

  .ShowPageLoading_page-modal__PqY_4 .ant-modal-content {

    --tw-shadow: 0 0 #0000 !important;

    --tw-shadow-colored: 0 0 #0000 !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.style_inputForm__2s1_3 .web2design-qrcode-icon,
    .style_inputForm__2s1_3 .divider-center.portkey-ant-divider-with-text,
    .style_inputForm__2s1_3 .social-icon-group-wrapper {

    display: none;
}
    .style_inputForm__2s1_3 .web2design-qrcode-icon,
    .style_inputForm__2s1_3 .divider-center.portkey-ant-divider-with-text,
    .style_inputForm__2s1_3 .login-primary-btn {

    margin-bottom: 1.5rem;
}
    .style_inputForm__2s1_3 .backleft-icon {

    display: none;
}

.style_qrcodeBox__fAl1I .back-icon-wrapper {

    display: none !important;
}

.style_qrcodeBox__fAl1I .backleft-icon {

    display: none !important;
}

.style_loginContainer__KHKLo {

    display: flex;

    height: 100%;

    width: 100%;

    flex-direction: column;

    align-items: center;

    justify-content: flex-end;

    background-size: cover !important;

    background-position: center;

    background-repeat: no-repeat;

    padding-left: 2.5rem;

    padding-right: 2.5rem;

    font-size: 1rem;

    line-height: 1.5rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo {

        justify-content: flex-start;

        padding-top: 46.7vh;
    }
}

.style_loginContainer__KHKLo .style_more__iaSzF {

    z-index: 20;
}

.style_loginContainer__KHKLo .style_more__iaSzF {

    padding-bottom: 2rem;
}

.style_loginContainer__KHKLo .style_more__iaSzF {

    font-family: PaytoneOne, sans-serif;
}

.style_loginContainer__KHKLo .style_more__iaSzF {

    font-size: 1.25rem;
}

.style_loginContainer__KHKLo .style_more__iaSzF {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.style_loginContainer__KHKLo .style_more__iaSzF {

    text-decoration-line: underline;
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_more__iaSzF {

        padding-bottom: 0px;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_more__iaSzF {

        font-size: 2rem;
    }
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    position: relative;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    z-index: 20;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    margin-bottom: 1.5rem;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    display: flex;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    height: 3rem;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    width: 100%;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    align-items: center;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    justify-content: center;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    border-radius: 624.9375rem;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    --tw-bg-opacity: 1;

    background-color: rgb(93 66 255 / var(--tw-bg-opacity));
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    padding-left: 1.25rem;

    padding-right: 1.25rem;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    font-family: PaytoneOne, sans-serif;
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    font-size: 1.25rem;
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

        height: 5.125rem;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

        width: 28.125rem;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

        justify-content: flex-end;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

        border-radius: 2.375rem;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

        padding-left: 2rem;

        padding-right: 2rem;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

        font-size: 2rem;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

        line-height: 1.5rem;
    }
}

.style_loginContainer__KHKLo .style_loginBtn__kzqkd,
  .style_loginContainer__KHKLo .style_unlockBtn__WDfIr,
  .style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    box-shadow: 0 0.1875rem 1px 0 #6f6cff inset, 0 0.25rem 0 0 #1d089b;
    text-shadow: 0 0 1px #4b35d0;
  }

.style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

    margin-bottom: 5.875rem;
}

.style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

    height: 2.75rem;
}

.style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

    border-radius: 1.375rem;
}

.style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

    --tw-bg-opacity: 1;

    background-color: rgb(161 90 28 / var(--tw-bg-opacity));
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

        margin-bottom: 0px;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

        margin-top: 3.7vh;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

        height: 4.375rem;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

        justify-content: center;
    }
}

@media (min-width: 768px) {
    .style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

        border-radius: 2.1875rem;
    }
}

.style_loginContainer__KHKLo .style_unlockBtn__WDfIr {

    box-shadow: 0 0.1875rem 1px 0 #d18a4c inset, 0 0.375rem 0 0 #773E0C;
    text-shadow: 0 0 1px #953d22;
  }

.style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    --tw-bg-opacity: 1;

    background-color: rgb(0 166 239 / var(--tw-bg-opacity));
}

.style_loginContainer__KHKLo .style_loginBtnYellow__mD4h4 {

    box-shadow: 0 0.1875rem 1px 0 #29bdff inset, 0 0.25rem 0 0 #005e88;
    text-shadow: 0 0 1px #0090d0;
  }

.style_loginBtnIcon__REi53,
.style_loginBtnBlueIcon__pOfN5 {

    display: flex;

    align-items: center;

    font-size: 1.5rem;

    line-height: 2rem;
}

@media (min-width: 768px) {
    .style_loginBtnIcon__REi53,
.style_loginBtnBlueIcon__pOfN5 {

        left: 2rem;

        font-size: 2.75rem;
    }
}

.style_loginBtnIcon__REi53 path, .style_loginBtnBlueIcon__pOfN5 path {

    fill: #fff;
}

.style_loginMethodModal__cr6oI {

    width: 46.875rem !important;

    border-radius: 2rem;
}

.style_loginMethodModal__cr6oI .ant-modal-content {

    border-radius: 2rem !important;
}

.style_loginMethodModal__cr6oI .ant-modal-body {

    border-radius: 2rem;
}

.style_loginMethodModal__cr6oI .ant-modal-body {

    border-width: 1rem;
}

.style_loginMethodModal__cr6oI .ant-modal-body {

    --tw-border-opacity: 1;

    border-color: rgb(154 83 31 / var(--tw-border-opacity));
}

.style_loginMethodModal__cr6oI .ant-modal-body {

    padding-left: 2rem !important;

    padding-right: 2rem !important;
}

.style_loginMethodModal__cr6oI .ant-modal-body {

    padding-top: 3rem !important;

    padding-bottom: 3rem !important;
}

.style_loginMethodModal__cr6oI .ant-modal-body {

    font-size: 2rem;
}

.style_loginMethodModal__cr6oI .ant-modal-body {

    line-height: 1.5rem;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    position: relative;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    margin-left: 6.0625rem;

    margin-right: 6.0625rem;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    margin-bottom: 1.25rem !important;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    display: flex;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    height: 4.8125rem !important;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    align-items: center;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    justify-content: center !important;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    border-radius: 2.4375rem !important;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    background-color: var(--confirm-btn-bg);
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    padding-left: 2.5rem !important;

    padding-right: 2.5rem !important;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    font-family: PaytoneOne, sans-serif;
}

.style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    margin-bottom: 2.5rem !important;
}

.style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    text-align: center;
}

.style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    font-family: PaytoneOne, sans-serif;
}

.style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    font-size: 2rem !important;
}

.style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    line-height: 1.5rem;
}

.style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    --tw-text-opacity: 1;

    color: rgb(149 61 34 / var(--tw-text-opacity));
}

.style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    position: absolute;
}

.style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    right: 8Px !important;
}

.style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    top: 8Px !important;
}

.style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    height: 3rem;
}

.style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    width: 3rem;
}

.style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    font-size: 1.875rem !important;
}

.style_loginMethodModal__cr6oI .style_loginBtnBlueIcon__pOfN5 {

    left: 2.79375rem;
}

.style_loginMethodModal__cr6oI .style_loginBtnBlueIcon__pOfN5 path {

    fill: #fff;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    position: relative;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    margin-bottom: 1rem;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    display: flex;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    height: 3rem;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    align-items: center;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    justify-content: center;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    border-radius: 2.25rem;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    background-color: var(--confirm-btn-bg);
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    padding-left: 1rem;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    font-family: PaytoneOne, sans-serif;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    font-size: 1.25rem;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
    .style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd {

        font-size: 2rem;
    }
}

.style_loginMethodDrawer__tDMTu,
.style_loginMethodModal__cr6oI {

    font-size: 1rem;

    line-height: 1.5rem;
}

.style_loginMethodDrawer__tDMTu .ant-drawer-header,
    .style_loginMethodDrawer__tDMTu .ant-modal-header,
    .style_loginMethodDrawer__tDMTu .ant-modal-footer,
    .style_loginMethodModal__cr6oI .ant-drawer-header,
    .style_loginMethodModal__cr6oI .ant-modal-header,
    .style_loginMethodModal__cr6oI .ant-modal-footer {

    display: none;
}

.style_loginMethodDrawer__tDMTu .ant-drawer-content, .style_loginMethodModal__cr6oI .ant-drawer-content {

    border-top-left-radius: 1rem;
}

.style_loginMethodDrawer__tDMTu .ant-drawer-content, .style_loginMethodModal__cr6oI .ant-drawer-content {

    border-top-right-radius: 1rem;
}

.style_loginMethodDrawer__tDMTu .ant-modal-content, .style_loginMethodModal__cr6oI .ant-modal-content {

    overflow: auto;
}

.style_loginMethodDrawer__tDMTu .ant-modal-content, .style_loginMethodModal__cr6oI .ant-modal-content {

    border-radius: 1rem;
}

.style_loginMethodDrawer__tDMTu .ant-modal-content, .style_loginMethodModal__cr6oI .ant-modal-content {

    background-color: var(--color-main);
}

.style_loginMethodDrawer__tDMTu .ant-modal-content, .style_loginMethodModal__cr6oI .ant-modal-content {

    font-size: 1rem;

    line-height: 1.5rem;
}

.style_loginMethodDrawer__tDMTu .ant-modal-content, .style_loginMethodModal__cr6oI .ant-modal-content {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.style_loginMethodDrawer__tDMTu .ant-drawer-wrapper-body, .style_loginMethodModal__cr6oI .ant-drawer-wrapper-body {

    overflow: auto;
}

.style_loginMethodDrawer__tDMTu .ant-drawer-wrapper-body, .style_loginMethodModal__cr6oI .ant-drawer-wrapper-body {

    background-color: var(--color-main);
}

.style_loginMethodDrawer__tDMTu .ant-drawer-wrapper-body, .style_loginMethodModal__cr6oI .ant-drawer-wrapper-body {

    font-size: 1rem;

    line-height: 1.5rem;
}

.style_loginMethodDrawer__tDMTu .ant-drawer-wrapper-body, .style_loginMethodModal__cr6oI .ant-drawer-wrapper-body {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.style_loginMethodDrawer__tDMTu .ant-drawer-body,
    .style_loginMethodDrawer__tDMTu .ant-modal-body,
    .style_loginMethodModal__cr6oI .ant-drawer-body,
    .style_loginMethodModal__cr6oI .ant-modal-body {

    position: relative;
}

.style_loginMethodDrawer__tDMTu .ant-drawer-body,
    .style_loginMethodDrawer__tDMTu .ant-modal-body,
    .style_loginMethodModal__cr6oI .ant-drawer-body,
    .style_loginMethodModal__cr6oI .ant-modal-body {

    padding-left: 2.5rem;

    padding-right: 2.5rem;
}

.style_loginMethodDrawer__tDMTu .ant-drawer-body,
    .style_loginMethodDrawer__tDMTu .ant-modal-body,
    .style_loginMethodModal__cr6oI .ant-drawer-body,
    .style_loginMethodModal__cr6oI .ant-modal-body {

    padding-bottom: 1.5rem;
}

.style_loginMethodDrawer__tDMTu .ant-drawer-content-wrapper, .style_loginMethodModal__cr6oI .ant-drawer-content-wrapper {

    min-height: 24.5rem;
}

.style_loginMethodDrawer__tDMTu .style_loginBtn__kzqkd, .style_loginMethodModal__cr6oI .style_loginBtn__kzqkd {
    box-shadow: 0 0.1875rem 1px 0 #d18a4c inset, 0 0.375rem 0 0 #773E0C;
    text-shadow: 0 0 1px #953d22;
  }

.style_loginMethodDrawer__tDMTu .style_drawerHeader__3mmrz, .style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    margin-bottom: 2rem;
}

.style_loginMethodDrawer__tDMTu .style_drawerHeader__3mmrz, .style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    text-align: center;
}

.style_loginMethodDrawer__tDMTu .style_drawerHeader__3mmrz, .style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    font-family: PaytoneOne, sans-serif;
}

.style_loginMethodDrawer__tDMTu .style_drawerHeader__3mmrz, .style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    font-size: 1.5rem;

    line-height: 2rem;
}

.style_loginMethodDrawer__tDMTu .style_drawerHeader__3mmrz, .style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    line-height: 1.5rem;
}

.style_loginMethodDrawer__tDMTu .style_drawerHeader__3mmrz, .style_loginMethodModal__cr6oI .style_drawerHeader__3mmrz {

    --tw-text-opacity: 1;

    color: rgb(149 61 34 / var(--tw-text-opacity));
}

.style_loginMethodDrawer__tDMTu .style_drawer__close__y0eSm, .style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    position: absolute;
}

.style_loginMethodDrawer__tDMTu .style_drawer__close__y0eSm, .style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    right: 0.75rem;
}

.style_loginMethodDrawer__tDMTu .style_drawer__close__y0eSm, .style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    top: 0.75rem;
}

.style_loginMethodDrawer__tDMTu .style_drawer__close__y0eSm, .style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

    width: 1.75rem;
}

@media (min-width: 768px) {
    .style_loginMethodDrawer__tDMTu .style_drawer__close__y0eSm, .style_loginMethodModal__cr6oI .style_drawer__close__y0eSm {

        width: 3rem;
    }
}

.style_loginMethodDrawer__tDMTu .style_drawer__close__y0eSm svg, .style_loginMethodModal__cr6oI .style_drawer__close__y0eSm svg {

    height: 1.5rem;
}

.style_loginMethodDrawer__tDMTu .style_drawer__close__y0eSm svg, .style_loginMethodModal__cr6oI .style_drawer__close__y0eSm svg {

    width: 1.5rem;
}
.style_unlock_footer_text__6Zq7b {
  color: #55585E;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.25rem;
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}
.style_unlock_footer_text__6Zq7b .style_unlock_footer_highlight__soadF {
    color: #5D42FF;
  }

/* stylelint-disable number-max-precision */
.style_getMoreACORNSModal__7TbmF {
    position: relative;
    overflow: hidden;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.style_getMoreACORNSModal__7TbmF .ant-modal-content .ant-modal-close {
    position: absolute;
}
.style_getMoreACORNSModal__7TbmF .ant-modal-content .ant-modal-close {
    right: 0.25rem;
}
.style_getMoreACORNSModal__7TbmF .ant-modal-content .ant-modal-close .ant-modal-close-x {
    height: 100%;
}
.style_getMoreACORNSModal__7TbmF .ant-modal-content .ant-modal-close .ant-modal-close-x {
    width: 100%;
}
.style_getMoreACORNSModal__7TbmF .ant-modal-content .ant-modal-body {
    border-radius: 1rem;
}
.style_getMoreACORNSModal__7TbmF .ant-modal-content .ant-modal-body {
    padding-top: 0px;
}
.style_getMoreACORNSModal__7TbmF .ant-modal-content .ant-modal-body {
    line-height: 1.5rem;
}

.style_showBeanPassModal__mycdB {
    width: 22.375rem !important;
}

@media (min-width: 768px) {
    .style_showBeanPassModal__mycdB {
        width: 36.25rem !important;
    }
}

.style_getBeanPassModal__PH1_4 {
    width: 22.375rem !important;
}

@media (min-width: 768px) {
    .style_getBeanPassModal__PH1_4 {
        width: 46.875rem !important;
    }
}

@media (min-width: 768px) {
    .style_getBeanPassModal__PH1_4 .ant-modal-content {
        border-radius: 1.25rem;
    }
}

.style_getBeanPassModal__PH1_4 .ant-modal-content .style_confirmBtn__gxp_v {
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {
    .style_getBeanPassModal__PH1_4 .ant-modal-content .style_confirmBtn__gxp_v {
        height: 4.84375rem;
    }
}

@media (min-width: 768px) {
    .style_getBeanPassModal__PH1_4 .ant-modal-content .style_confirmBtn__gxp_v {
        border-radius: 2.42188rem;
    }
}

@media (min-width: 768px) {
    .style_getBeanPassModal__PH1_4 .ant-modal-content .style_confirmBtn__gxp_v {
        font-size: 2rem;
    }
}

@media (min-width: 768px) {
    .style_getBeanPassModal__PH1_4 .ant-modal-content .style_confirmBtn__gxp_v {
        line-height: 4.84375rem;
    }
}

.style_settingModal__DtexR .ant-modal-body {
    padding-bottom: 1.6875rem !important;
}

@media (min-width: 768px) {
    .style_settingModal__DtexR .ant-modal-body {
        padding-bottom: 3.84375rem !important;
    }
}

@media (min-width: 768px) {
    .style_settingModal__DtexR .ant-modal-body .style_modal__title__ceGws {
        height: 7.375rem !important;
    }
}

@media (min-width: 768px) {
    .style_settingModal__DtexR .ant-modal-body .style_modal__title__ceGws {
        font-size: 2rem !important;
    }
}

@media (min-width: 768px) {
    .style_settingModal__DtexR .ant-modal-body .style_modal__title__ceGws {
        line-height: 1.5rem !important;
    }
}

@media (min-width: 768px) {
    .style_settingModal__DtexR .ant-modal-content {
        width: 36.25rem !important;
    }
}

@media (min-width: 768px) {
    .style_settingModal__DtexR .ant-modal-content {
        border-radius: 1.25rem;
    }
}

.style_assetModal__rFbTr .ant-modal-footer {
    display: none;
}

.style_commonBtn__0Qhle {

  height: 3rem;

  border-radius: 624.9375rem;

  --tw-bg-opacity: 1;

  background-color: rgb(231 75 68 / var(--tw-bg-opacity));

  text-align: center;

  font-family: PaytoneOne, sans-serif;

  font-size: 1.25rem;

  line-height: 1.75rem;

  line-height: 3rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));

  box-shadow: 0 0.1875rem 1px 0 #FC716B inset, 0 0.4375rem 0 0 #C23B35;
  text-shadow: 0 0 1px #C23B35
}

.style_headerContainer__KoD5O {

  display: flex;

  height: 6.25rem;

  width: 100%;

  flex-direction: column;

  gap: 0.25rem;

  --tw-bg-opacity: 1;

  background-color: rgb(161 90 28 / var(--tw-bg-opacity));

  padding-left: 0.75rem;

  padding-right: 0.75rem;

  padding-top: 0.5rem;

  padding-bottom: 0.5rem;

  background-image: url(/_next/static/media/top-menu-bg-m.6df0e799.png);
  background-size: cover;
}

  .style_headerContainer__KoD5O .style_header__menu__I9JiZ {

  display: flex;
}

  .style_headerContainer__KoD5O .style_header__menu__I9JiZ {

  width: 100%;
}

  .style_headerContainer__KoD5O .style_header__menu__I9JiZ {

  align-items: center;
}

.style_board__acorn__kzQGr {

  display: flex;

  height: 2.375rem;

  width: 9.875rem;

  align-items: center;

  padding-left: 0.25rem;

  background-image: url(/_next/static/media/button-menu-default-s.81a367d4.png);
  background-size: cover;
}

.style_board__acorn__kzQGr .style_board__acorn__number__5i33y {

  display: flex;
}

.style_board__acorn__kzQGr .style_board__acorn__number__5i33y {

  flex: 1 1 0%;
}

.style_board__acorn__kzQGr .style_board__acorn__number__5i33y {

  flex-wrap: wrap;
}

.style_board__acorn__kzQGr .style_board__acorn__number__5i33y {

  word-break: break-all;
}

.style_board__acorn__kzQGr .style_board__acorn__number__5i33y {

  padding-left: 0.75rem;
}

.style_board__acorn__kzQGr .style_board__acorn__number__5i33y {

  font-family: PaytoneOne, sans-serif;
}

.style_board__acorn__kzQGr .style_board__acorn__number__5i33y {

  font-size: 1rem;
}

.style_board__acorn__kzQGr .style_board__acorn__number__5i33y {

  line-height: 1rem;
}

.style_board__acorn__kzQGr .style_board__acorn__number__5i33y {

  --tw-text-opacity: 1;

  color: rgb(149 61 34 / var(--tw-text-opacity));
}

.style_board__tooltip__cmN7T .ant-tooltip-inner {

  border-radius: 1rem;
}

.BoardLeft_game__pc__side__lskVz {
    position: relative;
    display: flex;
    height: 100%;
    flex-basis: 33.333333%;
    align-items: center;
    justify-content: center
}

.style_asset__m57rP {

    height: 100%;

    width: 100%;

    overflow: hidden;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

  .style_asset__m57rP .portkey-ui-asset-wrapper {

    height: 100%;
}

  .style_asset__m57rP .portkey-ui-asset-wrapper {

    width: 100%;
}

  .style_asset__m57rP .portkey-ui-asset-wrapper {

    overflow: auto;
}

  .style_asset__m57rP .portkey-ui-ramp-frame .portkey-ui-ramp-content .portkey-ui-ramp-input .portkey-ant-input-affix-wrapper {

    padding-top: 0px !important;

    padding-bottom: 0px !important;
}

.style_taskModal__018CE {

    overflow: hidden;

    padding-left: 0px;

    padding-right: 0px;
}

  .style_taskModal__018CE .ant-modal-content .ant-modal-body {

    border-radius: 1rem;
}

  .style_taskModal__018CE .ant-modal-content .ant-modal-body {

    padding-bottom: 1rem;
}

  .style_taskModal__018CE .ant-modal-content .ant-modal-close {

    position: absolute;
}

  .style_taskModal__018CE .ant-modal-content .ant-modal-close {

    top: -1rem;
}

  .style_taskModal__018CE .ant-modal-content .ant-modal-close {

    right: 0.5rem;
}

  .style_taskModal__018CE .ant-modal-content .ant-modal-close .ant-modal-close-x {

    height: 100%;
}

  .style_taskModal__018CE .ant-modal-content .ant-modal-close .ant-modal-close-x {

    width: 100%;
}

.style_mobileTaskModal__8I1Se .ant-modal-content .ant-modal-close {

    top: -0.5rem;
}

.style_task__ZXK4V {

    height: 2.5rem;

    width: 2.5rem;
}

.style_task-pc__XakLB {

    height: 5rem;

    width: 5rem;
}

.style_content__XB_AA {

    padding-left: 2rem;

    padding-right: 2rem;
}

.style_content__XB_AA::-webkit-scrollbar {

    margin-left: 0.5rem;
}

.style_content__XB_AA::-webkit-scrollbar {

    width: 1rem;
}

.style_content__XB_AA::-webkit-scrollbar-thumb {

    border-radius: 0.5rem !important;
}

.BoardRight_game__pc__side__z4viW {
    position: relative;
    display: flex;
    height: 100%;
    flex-basis: 66.666667%;
    align-items: center;
    justify-content: center
}

/* stylelint-disable number-max-precision */

.style_leaderBoardMobileModal__fUUFF .ant-modal-body {

    padding-top: 1rem !important;
}

.style_leaderBoardPcModal__LZRx_ .ant-modal-body {

    padding-top: 2rem !important;
}

.style_leaderBoardModal__jOB1x {

    position: relative;

    padding: 0px;
}

.style_leaderBoardModal__jOB1x .ant-modal-content {

    height: 100%;
}

.style_leaderBoardModal__jOB1x .ant-modal-content {

    border-radius: 2rem;
}

.style_leaderBoardModal__jOB1x .ant-modal-content {

    background-color: inherit;
}

.style_leaderBoardModal__jOB1x .ant-modal-content {

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.style_leaderBoardModal__jOB1x .ant-modal-content .ant-modal-close {

    position: absolute;
}

.style_leaderBoardModal__jOB1x .ant-modal-content .ant-modal-close {

    top: 0.25rem;
}

.style_leaderBoardModal__jOB1x .ant-modal-content .ant-modal-close {

    right: 0.25rem;
}

.style_leaderBoardModal__jOB1x .ant-modal-content .ant-modal-close .ant-modal-close-x {

    height: 100%;
}

.style_leaderBoardModal__jOB1x .ant-modal-content .ant-modal-close .ant-modal-close-x {

    width: 100%;
}

.style_scrollbar__0p7Iy::-webkit-scrollbar {

    width: 0px !important;
}

.style_pcDepositWrap__f1H4_ {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
}
  .style_pcDepositWrap__f1H4_ .etransfer-ui-deposit-title {
      display: none;
}
  .style_pcDepositWrap__f1H4_ .etransfer-ui-selected-data-wrapper,
    .style_pcDepositWrap__f1H4_ .calculator-header-title,
    .style_pcDepositWrap__f1H4_ .receive-amount,
    .style_pcDepositWrap__f1H4_ .deposit-address-label {
      --tw-text-opacity: 1;
      color: rgb(26 26 26 / var(--tw-text-opacity));
}
  .style_pcDepositWrap__f1H4_ .selected-token,
    .style_pcDepositWrap__f1H4_ .selected-chain {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
  .style_pcDepositWrap__f1H4_ .selected-token,
    .style_pcDepositWrap__f1H4_ .selected-chain,
    .style_pcDepositWrap__f1H4_ .you-pay-main,
    .style_pcDepositWrap__f1H4_ .deposit-address-wrapper,
    .style_pcDepositWrap__f1H4_ .etransfer-ui-deposit-retry {
      border-width: 1px !important;
}
  .style_pcDepositWrap__f1H4_ .selected-token,
    .style_pcDepositWrap__f1H4_ .selected-chain,
    .style_pcDepositWrap__f1H4_ .you-pay-main,
    .style_pcDepositWrap__f1H4_ .deposit-address-wrapper,
    .style_pcDepositWrap__f1H4_ .etransfer-ui-deposit-retry {
      border-style: solid !important;
}
  .style_pcDepositWrap__f1H4_ .selected-token,
    .style_pcDepositWrap__f1H4_ .selected-chain,
    .style_pcDepositWrap__f1H4_ .you-pay-main,
    .style_pcDepositWrap__f1H4_ .deposit-address-wrapper,
    .style_pcDepositWrap__f1H4_ .etransfer-ui-deposit-retry {
      --tw-border-opacity: 1 !important;
      border-color: rgb(161 90 28 / var(--tw-border-opacity)) !important;
}
  .style_pcDepositWrap__f1H4_ .etransfer-ui-deposit-tip {
      --tw-border-opacity: 1 !important;
      border-color: rgb(149 61 34 / var(--tw-border-opacity)) !important;
}
  .style_pcDepositWrap__f1H4_ .etransfer-ui-deposit-tip {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(232 209 174 / var(--tw-bg-opacity)) !important;
}
  .style_pcDepositWrap__f1H4_ .etransfer-ui-description-wrapper {
      border-width: 0px;
}
  .style_pcDepositWrap__f1H4_ .etransfer-ui-description-wrapper {
      --tw-bg-opacity: 1;
      background-color: rgb(232 209 174 / var(--tw-bg-opacity));
}
  .style_pcDepositWrap__f1H4_ .etransfer-ui-calculator {
      --tw-bg-opacity: 1;
      background-color: rgb(232 209 174 / var(--tw-bg-opacity));
}
  .style_pcDepositWrap__f1H4_ .etransfer-ui-selected-data-wrapper .etransfer-ui-selected-token-wrapper:not(:first-child),
    .style_pcDepositWrap__f1H4_ .etransfer-ui-selected-data-wrapper .etransfer-ui-selected-chain-wrapper:not(:first-child),
    .style_pcDepositWrap__f1H4_ .etransfer-ui-selected-data-wrapper .etransfer-ui-selected-token-wrapper .etransfer-ui-dynamic-arrow,
    .style_pcDepositWrap__f1H4_ .etransfer-ui-selected-data-wrapper .space,
    .style_pcDepositWrap__f1H4_ .etransfer-ui-row-center,
    .style_pcDepositWrap__f1H4_ .etransfer-ui-description-wrapper {
      display: none !important;
    }
  .style_pcDepositWrap__f1H4_ .etransfer-ui-deposit-select-token {
      pointer-events: none;
    }
  .style_pcDepositWrap__f1H4_ .etransfer-ui-check-txn-btn {
      border-width: 0px;
}
  .style_pcDepositWrap__f1H4_ .etransfer-ui-check-txn-btn {
      --tw-bg-opacity: 1;
      background-color: rgb(154 83 31 / var(--tw-bg-opacity));
}

.style_mobileDepositWrap__V_lSN {
      padding: 0px !important;
}

.style_mobileDepositWrap__V_lSN .etransfer-ui-common-svg svg {
      height: auto !important;
}

.style_mobileDepositWrap__V_lSN .etransfer-ui-common-svg svg {
      width: auto !important;
}

.style_mobileDepositWrap__V_lSN .calculator-header-title,
    .style_mobileDepositWrap__V_lSN .receive-amount {
      --tw-text-opacity: 1;
      color: rgb(26 26 26 / var(--tw-text-opacity));
}

.style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token-network,
    .style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token-chain {
      border-width: 1px !important;
}

.style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token-network,
    .style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token-chain {
      border-style: solid !important;
}

.style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token-network,
    .style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token-chain {
      --tw-border-opacity: 1 !important;
      border-color: rgb(161 90 28 / var(--tw-border-opacity)) !important;
}

.style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token-network,
    .style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token-chain {
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.style_mobileDepositWrap__V_lSN .etransfer-ui-calculator {
      --tw-bg-opacity: 1;
      background-color: rgb(232 209 174 / var(--tw-bg-opacity));
}

.style_mobileDepositWrap__V_lSN .etransfer-ui-calculator .etransfer-ui-calculator-content {
      flex-direction: column;
}

.style_mobileDepositWrap__V_lSN .you-pay-main {
      border-color: rgba(161,90,28,0.40) !important;
}

.style_mobileDepositWrap__V_lSN #etransfer-ui-deposit-select-token-result .etransfer-ui-dynamic-arrow,
    .style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token-chain,
    .style_mobileDepositWrap__V_lSN .etransfer-ui-description-wrapper {
      display: none !important;
    }

.style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-select-token {
      pointer-events: none;
    }

.style_mobileDepositWrap__V_lSN .etransfer-ui-deposit-tip {
      background-color: transparent;
      border-color: #fff;
    }

.style_mobileDepositWrap__V_lSN .etransfer-ui-exchange-rate .etransfer-ui-flex-row-center {
      align-items: start;
    }

.style_mobileDepositWrap__V_lSN .etransfer-ui-exchange-rate div:last-child {
      text-align: end;
      min-width: 5rem;
    }

.style_mobileDepositWrap__V_lSN .etransfer-ui-check-txn-btn {
      border-width: 0px;
}

.style_mobileDepositWrap__V_lSN .etransfer-ui-check-txn-btn {
      --tw-bg-opacity: 1;
      background-color: rgb(154 83 31 / var(--tw-bg-opacity));
}

.style_awakenWrap__khra9 {
  margin-bottom: 1.5rem;
}
  .style_awakenWrap__khra9 .swap-input-wrapper .swap-input-row2 .select-token-wrap {
      background-color: #a15a1c;
    }
  .style_awakenWrap__khra9 .swap-input-wrapper {
      border: 1px solid #a15a1c;
    }
  .style_awakenWrap__khra9 .swap-input-wrapper .swap-input-row2 .select-token-wrap,
      .style_awakenWrap__khra9 .swap-input-wrapper .swap-input-row2 .show-token-wrap {
        pointer-events: none;
      }
  .style_awakenWrap__khra9 .swap-input-wrapper .swap-input-row2 .show-token-wrap {
        background-color: #f7edd5;
      }
  .style_awakenWrap__khra9 .swap-input-wrapper .swap-input-row2 .show-token-wrap .portkey-swap-common-svg {
          display: none;
        }
  .style_awakenWrap__khra9 .swap-input-wrapper .swap-input-row3 .show-max {
      border-color: #953d22;
      color: #953d22;
    }
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-token-switch-wrap .swap-token-switch-btn {
      display: none;
    }
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button {
  background-color: var(--confirm-btn-bg);
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button {
  text-align: center;
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button {
  font-family: PaytoneOne, sans-serif;
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button {
  line-height: 3rem;
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button span {
  font-family: PaytoneOne, sans-serif;
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button {

      box-shadow: 0 0.1875rem 1px 0 #d18a4c inset, 0 0.375rem 0 0 #773e0c;
      text-shadow: 0 0 1px #953d22;
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button:hover {
  background-color: var(--confirm-btn-bg);
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button.btn-error {
  --tw-bg-opacity: 1;
  background-color: rgb(231 75 68 / var(--tw-bg-opacity));
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button.btn-error:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(231 75 68 / var(--tw-bg-opacity));
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button.btn-error:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button.btn-tip {
  --tw-bg-opacity: 1;
  background-color: rgb(172 169 160 / var(--tw-bg-opacity));
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button.btn-tip {
  opacity: 1;
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button.btn-tip:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(172 169 160 / var(--tw-bg-opacity));
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-btn-wrap .common-button.btn-tip:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-price-swap-info .icon-edit-icon path {
        fill: #773e0c;
      }
  .style_awakenWrap__khra9 .swap-panel-wrapper .swap-price-swap-info .icon-edit-icon .awk-common-tool-tip.ant-tooltip-open .common-question {
            background-image: none;
          }
  .style_awakenWrap__khra9 .swap-price-swap-info .common-tooltip {
        padding-bottom: 0;
      }
  .style_awakenWrap__khra9 .swap-price-swap-info .common-tooltip .portkey-trade-ant-tooltip-arrow::before {
            background-color: #e8d1ae;
          }
  .style_awakenWrap__khra9 .swap-price-swap-info .common-tooltip .portkey-trade-ant-tooltip-inner {
          background-color: #e8d1ae;
        }
  .style_awakenWrap__khra9 .portkey-trade-ant-modal,
    .style_awakenWrap__khra9 .portkey-trade-ant-modal-content,
    .style_awakenWrap__khra9 .portkey-trade-ant-modal-body {
      border-radius: 0.5rem !important;
    }
  .style_awakenWrap__khra9 .portkey-trade-ant-modal-body {
      overflow: hidden !important;
    }

